<template>
  <q-layout>
    <q-page-container>
      <q-page>
        <pdf-visor
          v-if="!!src && !!fileName"
          :key="src"
          :src="src"
          :file-name="fileName"
          style="width: 100%;min-height: inherit;"
        />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import pdfVisor from 'components/pdf-visor'

export default {
  name: 'pdf-visor-page',
  components: { pdfVisor },
  data () {
    return {
      src: '',
      fileName: ''
    }
  },
  mounted () {
    this.src = this.$route.query.src
    this.fileName = this.$route.query.fileName
  }
}
</script>
